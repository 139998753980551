import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StoreSubscription } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { fullNameFromContact } from 'src/lib/newBackendTypes';
import { State } from '../../reducers';
import { SET_ANCHOR_POINT, SET_USER } from '../../reducers/actions';
import { Store } from '../../services/store.service';

@UntilDestroy()
@Component({
  selector: 'roco-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  username: StoreSubscription<string>;

  authorized: endpointsAuthorized;

  get listTasksAuthorized() {
    return this.authorized[endpoints.listTasks];
  }

  constructor(
    private router: Router,
    private store: Store,
  ) {
    this.username = this.store.subscribe(
      (state: State) => {
        return state.user.user ? fullNameFromContact(state.user.user) : '';
      },
      [SET_USER],
    );

    endpointAuthorizationSubscription(store, this);
  }

  ngOnInit(): void {
    let target = sessionStorage.getItem('thalos-url-target');
    if (!!target && target.length > 1) {
      sessionStorage.removeItem('thalos-url-target');
      this.router.navigate([target]);
    }

    this.store.dispatch({ type: SET_ANCHOR_POINT, payload: null });
  }

  ngOnDestroy(): void {
    this.username.unsubscribe();
  }
}
