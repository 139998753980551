import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { provideRouter, Route } from '@angular/router';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { lastValueFrom } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { endpoints } from 'src/lib/apiEndpoints';
import { AppComponent } from './core/components/app/app.component';
import { ErrorComponent } from './core/components/error/error.component';
import { HomeComponent } from './core/components/home/home.component';
import { LoginComponent } from './core/components/login/login.component';
import { LoginReturnGuard } from './core/guards/login-return.guard';
import { LOADED_USER_ENDPOINTS, LOADED_USER_GRAPHQLS } from './core/reducers/actions';
import { MasterResolver } from './core/resolvers/master-resolver';
import { AuthGuard } from './core/services/authentication.guard';
import { DynamicMenusService } from './core/services/dynamic-menu.service';
import { Store } from './core/services/store.service';
import { ExistingGraphql } from 'src/lib/graphql/graphQlEnums';

export function menuFactory(dms: DynamicMenusService, injector: Injector, store: Store, http: HttpClient) {
  return async () => {
    const uri = `${environment.apiHost}${endpoints.listrpcids}`;
    const procedures: endpoints[] = await lastValueFrom(http.post<endpoints[]>(uri, { args: { filters: {} } }).pipe(catchError((err) => [])));
    const uriGraphql = `${environment.apiHost}${endpoints.listGraphqlRpcIds}`;
    const proceduresGraphql: ExistingGraphql[] = await lastValueFrom(http.post<ExistingGraphql[]>(uriGraphql, { args: { filters: {} } }).pipe(catchError((err) => [])));
    //api call will fail if user not logged in
    if (!procedures && !proceduresGraphql) return;
    //need userApprovalTypes to be set before building menu, so we can know whether or not the user is an approver
    let storeLoaded = store.subscribe((state) => state.user.userApprovalTypes);
    await lastValueFrom(
      storeLoaded.$.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    store.dispatch({ type: LOADED_USER_ENDPOINTS, payload: procedures });
    store.dispatch({ type: LOADED_USER_GRAPHQLS, payload: proceduresGraphql });
    storeLoaded.unsubscribe();
    let router = injector.get(Router);
    return dms.buildPermittedMenu().then(addRoutesToMenu(router));
  };
}

export function addRoutesToMenu(router: Router) {
  return (menu: Route[]) => {
    let portal = router.config.find((r) => {
      return r.path === 'portal';
    });
    if (!!portal) portal.children = [...portal.children.filter((c) => !c?.data?.flexView), ...menu];
  };
}

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/portal' },
  {
    resolve: { commonData: MasterResolver },
    path: 'portal',
    component: AppComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'core',
        loadChildren: () => import('./+modules/+core/core.module').then((m) => m.CoreModule),
      },
      {
        path: 'trading',
        loadChildren: () => import('./+modules/+trading/trading.module').then((m) => m.TradingModule),
      },
      {
        path: 'it',
        loadChildren: () => import('./+modules/+it/it.module').then((m) => m.ItModule),
      },
      {
        path: 'quality-control',
        loadChildren: () => import('./+modules/+quality-control/quality-control.module').then((m) => m.QualityControlModule),
      },
      {
        path: 'logistics',
        loadChildren: () => import('./+modules/+logistics/logistics.module').then((m) => m.LogisticsModule),
      },
      {
        path: 'accounting',
        loadChildren: () => import('./+modules/+accounting/accounting.module').then((m) => m.AccountingModule),
      },
      {
        path: 'hedging',
        loadChildren: () => import('./+modules/+hedging/hedging.module').then((m) => m.HedgingModule),
      },
      {
        path: 'metal-control',
        loadChildren: () => import('./+modules/+metal-control/metal-control.module').then((m) => m.MetalControlModule),
      },
      {
        path: 'flex',
        loadChildren: () => import('./+modules/+flex/flex.module').then((m) => m.FlexModule),
      },
      {
        path: 'risk-management',
        loadChildren: () => import('./+modules/+risk-management/risk-management.module').then((m) => m.RiskManagementModule),
      },
    ],
  },
  { path: 'auth', component: MsalRedirectComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoginReturnGuard] },
  {
    path: 'error',
    pathMatch: 'prefix',
    children: [
      { path: ':error', component: ErrorComponent },
      { path: ':error/:requestId', component: ErrorComponent },
    ],
  },
  { path: '**', redirectTo: '/error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [
    provideRouter(routes),
    DynamicMenusService,
    {
      provide: APP_INITIALIZER,
      deps: [DynamicMenusService, Injector, Store, HttpClient],
      multi: true,
      useFactory: menuFactory,
    },
  ],
})
export class AppRoutingModule {}
