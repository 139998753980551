import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import FontFaceObserver from 'fontfaceobserver';
import { combineLatest, from } from 'rxjs';
import { CommonDataService } from '../services/common-data.service';

export const MasterResolver: ResolveFn<any> = () => {
  const commonData = inject(CommonDataService);

  const font = new FontFaceObserver('font-awesome');
  const promise = font.load();

  if (promise && promise.then && typeof promise.then === 'function') return combineLatest([from(promise), commonData.dataLoaded()]);

  return commonData.dataLoaded();
};
